import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Form, Input, notification } from 'antd';
import './Verification.css';

const formItemLayout = {
labelCol: { span: 24 },
wrapperCol: { span: 24 },
};

@inject('store')
@observer
class VerificationForm extends Component {
state = {
isVerified: false,
};

handleSubmit = async (values) => {
const { store } = this.props;
try {
const result = await store.api.post('/user/verify', values);
if (result.status === 200) {
this.setState({ isVerified: true });
notification.success({
message: 'Account Verified',
description: 'Congratulations! Your account has been verified.',
});
}
} catch (error) {
console.log("Error while verifying user:", error);
}
};

render() {
const { isVerified } = this.state;
if (isVerified) {
return <h3 style={{ textAlign: 'center' }}>Your account has been verified!</h3>;
}
return (
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
<Form {...formItemLayout} style={{ width: '40%' }} onFinish={this.handleSubmit}>
<Form.Item>
<h2 style={{ textAlign: 'center', fontSize: '40px' }}>Verification Token</h2>
</Form.Item>
<Form.Item
name="verification_token"
rules={[{ required: true, message: 'Please input your verification token!' }]}
style={{ textAlign: 'center' }}
>
<Input className="bubble-input" style={{ width: '80%', height: '50px', borderRadius: '10px', fontSize: '30px' }} />
</Form.Item>
<Form.Item style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" style={{ width: '40%', height: '50px', fontSize: '20px' }}>
          Verify
        </Button>
      </Form.Item>
    </Form>
  </div>
);
}
}

export default VerificationForm;