import { AcademicCapIcon } from '@heroicons/react/solid'

const obj = {
  title: "Thematic Essay Plan Generator: Shakespeare's Macbeth",
  desc: "Use this tool to generate detailed essay plans for Shakespeare questions on Macbeth",
  category: "",
  Icon: AcademicCapIcon,
  permissions: ['user'],
  fromColor: "blue-400",
  toColor: "blue-600",
  to: "/ai/personal/macbethplanner",
  api: "/ai/personal/macbethplanner",
  output: {
    title: "Shakespeare Exam Question",
    desc: "The following exam question has been generated",
  },
  prompts: [
    {
      title: "Extract from Play",
      desc: "Enter text from a Shakespeare play",
      prompts: [
        {
          title: "Extract",
          attr: "extract",
          value: "",
          placeholder: "Enter text from a Shakespeare play here",
          label: "Text from a Shakespeare play",
          type: "textarea",
          rows: 15,
          maxLength: 6000,
          min: 20,
          required: true,
          error: "",
          example: "MACBETH\n\nThunder and lightning. Enter three Witches.\n\nFIRST WITCH\n\nWhen shall we three meet again\nIn thunder, lightning, or in rain?\n\nSECOND WITCH\n\nWhen the hurlyburly's done,\nWhen the battle's lost and won.\n\nTHIRD WITCH\n\nThat will be ere the set of sun.\n\n"
        },
        {
          title: "Essay Question",
          attr: "question",
          value: "",
          placeholder: "Enter the essay question here",
          label: "Essay Question",
          type: "textarea",
          rows: 5,
          maxLength: 6000,
          min: 20,
          required: true,
          error: "",
          example: "In your opinion, how does Shakespeare use the supernatural in this extract to explore themes of power and ambition?"
        },
      ],
      example: {
        output: "The following exam question has been generated: \n\nIn your opinion, how does Shakespeare use the supernatural in this extract to explore themes of power and ambition?",
      }
    }
  ]
}

export default obj
