import {
    AcademicCapIcon,
} from '@heroicons/react/solid'

const obj = {
    title: "English Language Paper One, Question Two",
    desc: "Use this tool to mark responses to questions on language in English Language, Paper One",
    category: "AQA",
    Icon: AcademicCapIcon,
    // tags: [],
    permissions: ['user'],

    fromColor: "green-400",
    toColor: "green-600",

    to: "/ai/writing/englishlanguageonetwo",
    api: "/ai/writing/englishlanguageonetwo",

    output: {
        title: "English Language Paper One, Question Two",
        desc: "Please wait as we query AI models. This can take up to 2 minutes",
        // Icon: RefreshIcon,
        // color: "",
    },

    prompts: [{
        title:"English Language Paper One, Question Two",
        desc: "Enter your response to the question",
        // n: 1,
        prompts: [
            { 
                title: "Question", 
                attr: "question",  
                value: "", 
                placeholder: "Enter the question here", 
                label: "Question",
                type: "textarea",
                maxLength: 200,
                required: true,
                error: "",
                example: "How does the writer use language here to describe the man?\n\nYou could include the writer’s choice of:\n\n•	words and phrases\n\n•	language features and techniques\n\n•	sentence forms."
            },
            { 
                title: "Extract", 
                attr: "extract",  
                value: "", 
                placeholder: "Enter the extract here", 
                label: "Extract",
                type: "textarea",
                maxLength: 1000,
                required: true,
                error: "",
                example: "But mean and sulky as the scene might be in the eyes of most of us, it was not altogether so in the Major’s, for along the coarse gravel footway was coming a thing which was to him what the passing of a religious procession is to a devout person. A large, heavy man, with fish-blue eyes and a ring of irradiating red beard, was pushing before him a barrow, which was ablaze with incomparable flowers. There were splendid flowers of almost every order, but the Major’s own favourite pansies predominated."
            },
            { 
                title: "Essay", 
                attr: "essay",  
                value: "", 
                placeholder: "Enter your essay response here", 
                label: "Your essay response to Question Two",
                type: "textarea",
                maxLength: 3500,
                // max: 100,
                min: 400,
                required: true,
                error: "",
                example: "The writer uses language throughout the extract to suggest both the man’s odd appearance and his importance to the Major. As the man appears in the story, his importance to the Major is likened to ‘a religious procession’ – the writer’s comparison thus shows the Major’s devotional importance to the man approaching him. Yet, in spite of this significance, the man is unflatteringly referred to as a ‘thing’, implying his unusual appearance. Hence the scheme of reference used to describe the man – his ‘fish-blue eyes’ and ‘irradiating red beard’ – stresses his rather uncanny characteristics\n\nThe writer is also playing with our expectations: a ‘large’ and ‘heavy’ man is the last person we might expect to be carrying a barrow full of flowers. Yet the focus on colour in his description also prepares readers for the contents of the wheelbarrow. When the writer describes how the barrow is ‘ablaze with incomparable flowers’, the word ‘ablaze’ implies the flowers are on fire, suggesting their vivid colour. This also ties to the focus on the man’s ‘irradiating’ beard: both words suggest how the flowers and man have a way of sparking your attention and desire.\n\nFinally, in the barrow itself the man carries ‘splendid flowers of almost every order’. This outlandish claim seems at odds with the small space of the wheelbarrow. The writer thus uses language to describe a man eccentric and infectious, both outlandish and fitting to his task."
            },
        ],
        example: {
            output: "Percentage likelihood the student's response has been AI generated: 0%\nAO2 Score: 7\nTotal Score: 7.0 / 8\nFeedback: \n\n > The student demonstrates a perceptive and detailed understanding of language by analyzing the effects of the writer's choices of language. They select a range of judicious textual detail and make sophisticated and accurate use of subject terminology.\n\n> For example, the student recognizes the writer's use of simile to compare the man's importance to the Major to a religious procession, and explains how this emphasizes the Major's reverence for the man. They also identify and analyze the use of the word 'thing' to describe the man, and explain how this implies his unusual appearance.\n\n> The student also comments on the writer's manipulation of the reader's expectations regarding the man's appearance and his choice of 'ablaze' and 'irradiating' to describe the man and his flowers, and how this emphasizes the vividness and bright color of both.\n\n> The student's response is well-written and show a deep understanding of the text and the language used in it. However, to achieve a score of 8/8, the student could improve their response by including more explicit reference to the sentence forms and language techniques used by the writer, and how they contribute to the overall effect of the passage.",         // outputs: [],
            // Icon: RefreshIcon,
            // color: "",
        }
    }]
    
}

export default obj
