import { AcademicCapIcon } from "@heroicons/react/solid";

const obj = {
  title: "Prompt Builder with Extract",
  desc: "Use this tool to build a prompt that will be the backbone to a marking tool",
  category: "trilogy",
  Icon: AcademicCapIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "purple-400",
  toColor: "purple-600",

  to: "/ai/writing/promptbuilderextract",
  api: "/ai/writing/promptbuilderextract",

  output: {
    title: "Prompt Builder",
    desc: "Please wait as we query AI models. This can take up to 2 minutes.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Prompt Builder",
      desc: "Enter your essay response",
      // n: 1,
      prompts: [
        {
          title: "Mark Scheme Context",
          attr: "context",
          value: "",
          placeholder: "Enter the context here",
          label: "Context",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example: ".",
        },
        {
          title: "Mark Scheme (Upload .pdf of the mark scheme).",
          name: "scheme", // This should match the multer configuration on the server
          attr: "scheme", // This should match the multer configuration on the server
          value: "",
          label: "Upload your PDF file",
          type: "file",
          required: true,
          error: "",
        },

        {
          title: "Essay Title1",
          attr: "essay_title1",
          value: "",
          placeholder: "Enter the essay title here",
          label: "Essay Title",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example:
            "Study Sources A and B.\n\n  How far do these two sources agree? Explain your answer using details of the sources.",
        },

        {
          title: "Extract1",
          attr: "extract1",
          value: "",
          placeholder: "Enter the first extract here",
          label: "Extract",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example: "",
        },

        {
          title: "Student 1's Essay (Upload .pdf).",
          name: "essays1", // This should match the multer configuration on the server
          attr: "essays1", // This should match the multer configuration on the server
          value: "",
          label: "Upload your PDF file",
          type: "file",
          required: true,
          error: "",
        },

        {
          title: "Feedback 1",
          attr: "feedback1",
          value: "",
          placeholder: "Enter the feedback here",
          label: "Feedback1",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example: "",
        },

        {
          title: "Essay Title2",
          attr: "essay_title2",
          value: "",
          placeholder: "Enter the essay title here",
          label: "Essay Title2",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example:
            "Study Sources A and B.\n\n  How far do these two sources agree? Explain your answer using details of the sources.",
        },

        {
          title: "Extract2",
          attr: "extract2",
          value: "",
          placeholder: "Enter the second extract here",
          label: "Extract2",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example: "",
        },

        {
          title: "Student 2's Essay (Upload .pdf).",
          name: "essays2", // This should match the multer configuration on the server
          attr: "essays2", // This should match the multer configuration on the server
          value: "",
          label: "Upload your PDF file",
          type: "file",
          required: true,
          error: "",
        },

        {
          title: "Feedback 2",
          attr: "feedback2",
          value: "",
          placeholder: "Enter the feedback here",
          label: "Feedback2",
          type: "textarea",
          maxLength: 750,
          required: true,
          error: "",
          example: "",
        },
      ],
      example: {
        output: ".", // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
