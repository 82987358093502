import React, { Component, Fragment, useState, useEffect } from 'react';
import { PencilIcon, ClockIcon, InformationCircleIcon, DuplicateIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';
import Header from '../Components/Header';
import Body, { Grid, Col } from '../Components/Body';
import Button from '../Components/Button';
import Output from '../Components/Output';
import Countdown from 'react-countdown';
import { withRouter } from 'react-router-dom';
import handwritingConfig  from '../tools/HandwritingConfig';
import { observable, makeObservable, computed, reaction } from 'mobx';
import { observer, inject,  } from 'mobx-react';
import EntryTabs from '../Components/EntryTabs';
import EntryPrompt from '../Components/EntryPrompt';
import EntryInput from '../Components/EntryInput';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import EntryN from '../Components/EntryN';
import Filter from 'bad-words';
import HandwritingConfigContext from './HandwritingConfigContext';

let filterBadWords = new Filter()



@inject('store')
@observer
class HandwritingTool extends Component {
  constructor(props) {
    reaction(
      () => this.output,
      (output) => {
        // Perform necessary updates when output changes
        console.log('Output changed:', output);
        this.setState({ output: output });
      }
    );
    super(props);
    makeObservable(this);

    reaction(
      () => this.output,
      (output) => {
        // Perform necessary updates when output changes
        console.log('Output changed:', output);
      }
    );

  this.isHandwritingModal = true;
  this.tool = this.props.store.getToolByUrl("/ai/writing/handwriting");
  

  // ...
  


  console.log("Tool props:", props); // Add this line
      const { store, location, match, forceConfig } = this.props;

  if (!this.tool) {
    window.location.href = '/';
  } else {
    this.prompts = [...this.tool.prompts];
  }

  this.state = {
    showModal: false,
    config: handwritingConfig,
  };

  this.fileRef = React.createRef();
  this.date = Date.now() + 1000;
  this.countdown = [];
  this.isHandwritingModalVisible = false;
}



  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

 
    @observable tool = {}

    @observable.deep prompts = []
    @observable currentPrompt = 0
    @observable currentOption = "Start Using"
    
    @observable error = ""

    @observable output = ""
    @observable outputs = []
    @observable code = ""

    fileRef = React.createRef();


    @observable loading = false
    
    @observable date = Date.now() + 1000
    countdown = []
    
    @observable isHandwritingModalVisible = false;

    

    @computed get isGenerateButtonDisabled(){

        

        if(this.loading){
            return true
        }
       
        return false
    }

    @computed get disabled(){

        if(this.prompts[this.currentPrompt].prompts[0].value.length < 1){
            return true
        }

        
        // this.prompts[this.currentPrompt].prompts[promptIndex].value
        return false
    }

    @computed get isMinLength() {
        
        if(!this.props.prompt.min){
            return false
        }
        if(!this.props.prompt.type === "number"){
            return false
        }
        
        return false
    }

    checkMinimumPrompts = () => {

        let shouldReturn = false
       
        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            if(prompt.min){
                if(prompt.value.length < prompt.min){
                    shouldReturn = true
                    prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
                }
            }
        })

        return shouldReturn
    }


    clearExampleTimeout = []

    onStartUsing  = async () => {
        this.loading = false
        this.error = ""
        this.clearExampleTimeout.forEach((item,index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })
        this.currentOption = "Start Using"
    }

    onExample = async () => {
        this.loading = true
        this.error = ""
        this.output = ""
        this.outputs = []
        this.code = ``

        this.currentOption = "Example"

        let totalLength = 0;

        this.clearExampleTimeout.forEach((item,index) => {
            clearTimeout(this.clearExampleTimeout[index])
        })
        
        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            this.prompts[this.currentPrompt].prompts[promptIndex].value = ""
        })

        this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
            for (let timeoutIndex = 0; timeoutIndex < prompt.example.length; timeoutIndex++) {
                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                    this.prompts[this.currentPrompt].prompts[promptIndex].value += prompt.example[timeoutIndex]
                }, 7 * totalLength)
            }
        })


      
        totalLength++
        
        if(this.prompts[this.currentPrompt].example.output){
            this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                this.output =  this.prompts[this.currentPrompt].example.output
                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                    this.loading = false
                    this.currentOption = "Start Using"
                    this.prompts[this.currentPrompt].prompts[0].value += " "
                }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500 )
    
            }, (7 * totalLength) + 500)
        }

        if(this.prompts[this.currentPrompt].example.code){
            totalLength++
            this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                this.code = `${this.prompts[this.currentPrompt].example.code}`
                this.loading = false
            }, (7 * totalLength) + 500)
        }

        if(this.prompts[this.currentPrompt].example.outputs){
            this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                this.outputs =  this.prompts[this.currentPrompt].example.outputs
    
                totalLength++
                this.clearExampleTimeout[totalLength] = setTimeout(()=> {
                    this.loading = false
                    this.currentOption = "Start Using"
                    // this.prompts[this.currentPrompt].prompts[0].value += " "
                }, 7 * totalLength + 500)
    
            }, (7 * totalLength) + 500)
        }
       

    }


    sanitizeAllPrompts = () => {
        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            if(!prompt.value){
                return false;
            }
            if(prompt.type === "number"){
                return false;
            }
           
            prompt.value = prompt.value.trim()

            if(filterBadWords.isProfane(prompt.value)){
                prompt.error = "Unsafe content , please try different language"
                throw Error("Unsafe content")
            }
        })
    }

    contentFilterFlagged = async (response) => {
        this.error = response.message

        this.date = Date.now() + 5000
        this.countdown.forEach(countdown => {
            if(countdown){
                countdown.stop()
                countdown.start()
            }
        })
        this.loading = false
    }

    checkOutput = (output) => {
        if(output){
            output = output.replace(/^\s+|\s+$/g, '')
            // output = output.replace(/\s{2,}/g, ' ')
        }
        return output
    }

    handleFileChange = (e, prompt) => {
        prompt.file = e.target.files[0];
      };
    @computed get language(){
        let language = ""
        this.prompts[this.currentPrompt].prompts.forEach(prompt => {
            if(prompt.attr === "language"){
                language = `${prompt.value}`
            }
        })
        return language
    }


    getModalConfiguration = () => {
      console.log("HandwritingConfig:", handwritingConfig);
      return handwritingConfig;
    };
    
    

    onGenerateClick = async () => {
      this.loading = true;


      try {
        this.error = "";
        this.output = "";
        this.code = ``;
        this.outputs = [];
    
        let checkMinimumPrompts = this.checkMinimumPrompts();
        if (checkMinimumPrompts) {
          this.loading = false;
          return false;
        }
    
        let hasFileInput = this.prompts[this.currentPrompt].prompts.some(
          (prompt) => prompt.type === "file"
        );
    
        let data;
        let config = {};
    
        if (hasFileInput) {
          data = new FormData();
    
          this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            if (prompt.type === "file" && prompt.file) {
              data.append(prompt.attr, prompt.file);
            } else {
              data.append(prompt.attr, prompt.value);
            }
          });
    
          config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
        } else {
          data = {};
    
          this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
            data[prompt.attr] = prompt.value;
          });
        }
    
        data.currentPrompt = this.prompts[this.currentPrompt].title;
        if (this.prompts[this.currentPrompt].n) {
          data.n = this.prompts[this.currentPrompt].n;
        }
    
        let response = await this.props.store.api.post(this.tool.api, data, config);

        console.log("onGenerateClick - response:", response);


        if (!response.data.success) {
          this.contentFilterFlagged(response.data);
          return false;
        }
    
        if (response.data.output) {
          this.output = this.checkOutput(response.data.output);
        }
    
        if (response.data.code) {
          this.code = response.data.code;
        }
    
        if (response.data.outputs) {
          this.outputs = response.data.outputs;
        }

        console.log("onGenerateClick - updated state:", {
          output: this.output,
          code: this.code,
          outputs: this.outputs,
        }); // Log the updated state
  
    
        this.date = Date.now() + 10000;
        this.countdown.forEach((countdown) => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.countdown.forEach((countdown) => {
          if (countdown) {
            countdown.stop();
            countdown.start();
          }
        });
        this.loading = false;
      }
    };
    
              render() {
                console.log("Tool render - state:", this.state);

                console.log("Tool render - config prop:", this.props.config);
                console.log("Tool render - config:", this.props.config);

                const config = this.props.config || this.state.config;

            // required for mobx to pick up deeply nested value 
            const currentValue = this.prompts[this.currentPrompt].prompts[0].value

            return (
                <>
                  <Helmet>
                    <title>{`${this.tool.title} Tool - OpenAI Template`}</title>
                  </Helmet>
                  <Header
                    title={this.tool.title}
                    desc={this.tool.desc}
                    Icon={this.tool.Icon}
                    fromColor={this.tool.fromColor}
                    category={this.tool.category}
                    options={[
                      {
                        title: "Start Using",
                        Icon: PencilIcon,
                        color: this.props.store.profile.credits ? "green" : "red",
                        onClick: this.onStartUsing,
                      },
                    ]}
                    currentOption={this.currentOption}
                  />
                  <Body>
                    
 


                    <Grid>
                            <Col span="6">

                                <EntryTabs
                                    prompts={this.prompts}
                                    currentPrompt={this.currentPrompt}
                                    onChange={this.handleCurrentPrompt}
                                />

                                {this.prompts.map((prompt, index) => 
                               <EntryPrompt
                               prompt={prompt}
                               key={index}
                               index={index}
                               disabled={this.disabled}
                               currentPrompt={this.currentPrompt}
                               extraButtons={prompt.extraButtons}
                             >
                                       {prompt.prompts.map((promptInput, index) => 
  <EntryInput
      prompt={promptInput}
      key={index}
      language={this.language}
      index={index}
      disabled={this.disabled}
      fileRef={this.fileRef}
      onFileChange={(e) => this.handleFileChange(e, promptInput)}
      onPerformRequest={this.onGenerateClick}
  />)}



                                    <div className="md:flex">
                                        <Countdown 
                                            ref={countdown => this.countdown[index] = countdown} 
                                            date={this.date} 
                                            renderer={props => 
                                            <Button 
                                                title={props.total ? `Timeout ${props.total/1000} secs` : "Perform Request"}
                                                disabled={props.total || this.isGenerateButtonDisabled}
                                                Icon={props.total ? ClockIcon : currentValue ? DuplicateIcon : PencilIcon} 
                                                onClick={this.onGenerateClick} 
                                            />} 
                                        /> 
                                        <EntryN 
                                            prompts={this.prompts} 
                                            currentPrompt={this.currentPrompt}
                                        />
                                    </div>



                                    {this.error && <div className="mt-4"><label 
                                        className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>
                                            {this.error}
                                    </label></div>}

                                    </EntryPrompt>
                                )}

                               
                            </Col>
                            <Col span="6">
                             <Output 
                                      title={this.tool.output.title}
                                    desc={this.tool.output.desc}

                                    Icon={this.tool.output.Icon || this.tool.Icon}
                                    fromColor={this.tool.fromColor}
                                    toColor={this.tool.toColor}
                                    
                                    loading={this.loading}
                                    output={this.output}
                                    outputs={this.outputs}
                                    code={this.code}
                                    language={this.language}
                                    
                                    outputsColor={this.tool.output.color}
                                    OutputsIcon={this.tool.output.Icon}
                            /> 
                        </Col> 
                    </Grid> 
                </Body>
            </>
        )
    }
}

const HandwritingToolWithStore = inject('store')(HandwritingTool);



function HandwritingModal({ showModal, closeModal, store, output }) {
    const [isOpen, setIsOpen] = useState(showModal);

    useEffect(() => {
      setIsOpen(showModal);
    }, [showModal, output]);

  console.log("HandwritingModal - handwritingConfig:", handwritingConfig);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-200 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-9xl py-100 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="relative px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <HandwritingConfigContext.Provider value={handwritingConfig}>
  <HandwritingTool store={store} output={output} />
</HandwritingConfigContext.Provider>
                </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}


export default HandwritingModal;