import {
	AcademicCapIcon,
} from '@heroicons/react/solid'

const obj = {
	title: "Comprehension: North London Collegiate",
	desc: "Use this tool to generate 11+ English Comprehension Papers with Model Answers. Modelled on North London Collegiate",
	category: "Experimental",
	Icon: AcademicCapIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "green-500",
	toColor: "green-500",

	to: "/ai/writing/elevencollegiate",
	api: "/ai/writing/elevencollegiate",

	output: {
		title: "The Questions & Model Answers Will Appear Here",
		desc: "This may take four to five minutes!",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"Input",
		desc: "Enter a topic or theme for the comprehension paper",
		// n: 1,
		prompts: [
			{ 
				title: "Topic", 
				attr: "topic",  
				value: "", 
				placeholder: "e.g. 'A secret cabin' or 'Hiking' or 'The storm'", 
				label: "What would you like the extract to be about?",
				// type: "textarea",
				maxLength: 250,
				// max: 100,
				min: 5,
				required: true,
				error: "",
				example: "Absailing",
			},
		],
		example: {
			output: "Read the following extract from Act 2 Scene 2 of Macbeth and then answer the question that follows.\nAt this point in the play, Macbeth has murdered Duncan and has returned to Lady Macbeth.\n\nMACBETH \nMethought I heard a voice cry, ‘Sleep no more: \nMacbeth does murder sleep’, the innocent sleep,\nSleep that knits up the ravelled sleeve of care,\nThe death of each day’s life, sore labour’s bath,\nBalm of hurt minds, great nature’s second course,\nChief nourisher in life’s feast.\nLADY MACBETH \nWhat do you mean? \nMACBETH \nStill it cried, ‘Sleep no more.",
				// outputs: [],
			// Icon: RefreshIcon,
			// color: "",
		}
	}]
		
}

export default obj
