import jobad from "./jobad";
import summarize from "./Macbeth Planner";
import codeInterpret from "./interpret";
import intro from "./intro";
import helloworld from "./helloworld";
import example from "./example";
import sats from "./SATs Paper Generator";
import aqashakespeare from "./aqashakespeare";
import shakespearemarking from "./Shakespeare Marking";
import modernplays from "./Modern Plays";
import modernnovels from "./Modern Novels";
import nineteenthcentury from "./Nineteenth Century Novels";
import modernprose from "./Modern Prose";
import englishlanguageonethree from "./English Language One Three";
import englishlanguageonetwo from "./English Language One Two";
import englishlanguageonefour from "./English Language One Four";
import englishlanguagetwotwo from "./English Language Two Two";
import englishlanguagetwothree from "./English Language Two Three";
import englishlanguagetwofour from "./English Language Two Four";
import englishlanguagetwofive from "./English Language Two Five";
import powerandconflict from "./Power and Conflict";
import loveandrelationships from "./Love and Relationships";
import shakespeareplanner from "./Shakespeare Planner";
import worldsandlives from "./Worlds and Lives";
import unseenpoetry from "./Unseen Poetry";
import anthology from "./Anthology";
import unseenedexcel from "./Unseen Edexcel";
import britishdepth from "./British Depth";
import britishstatement from "./British Statement";
import moderndepth from "./Modern Depth";
import moderntwenty from "./Modern Twenty";
import moderndrama from "./Modern Drama";
import heritage from "./Heritage";
import handwriting from "./Handwriting";
import cambprose from "./cambprose";
import cambpoetry from "./cambpoetry";
import cambdrama from "./cambdrama";
import handwritingtwo from "./HandwritingConfig";
import sevenplus from "./sevenplus";
import sevenplustwo from "./sevenplustwo";
import sevenplusthree from "./sevenplusthree";
import sevenplusfour from "./sevenplusfour";
import sevenplusfive from "./sevenplusfive";
import sevenplussix from "./sevenplussix";
import sevenplusseven from "./sevenplusseven";
import sevenpluseight from "./sevenpluseight";
import elevenplus from "./elevenplus";
import elevendulwich from "./elevendulwich";
import elevenallrounder from "./elevenallrounder";
import elevenpaulsgirls from "./elevenpaulsgirls";
import elevencollegiate from "./elevencollegiate";
import elevenwinchester from "./elevenwinchester";
import thirteencheltenham from "./thirteencheltenham";
import elevenmerchant from "./elevenmerchant";
import trilogy from "./trilogy";
import mcs from "./mcs";
import promptbuilder from "./promptbuilder";
import promptbuilderextract from "./promptbuilderextract";
import promptbuilder3extract from "./promptbuilder3extract";
import rentreview from "./rentreview";

import lease from "./lease";

const TOOLS = [
  modernprose,
  summarize,
  modernplays,
  modernnovels,
  shakespearemarking,
  promptbuilder,
  nineteenthcentury,
  powerandconflict,
  loveandrelationships,
  worldsandlives,
  unseenpoetry,
  englishlanguageonetwo,
  englishlanguageonethree,
  englishlanguageonefour,
  englishlanguagetwotwo,
  englishlanguagetwothree,
  englishlanguagetwofour,
  englishlanguagetwofive,
  shakespeareplanner,
  unseenedexcel,
  anthology,
  britishdepth,
  britishstatement,
  moderndepth,
  moderntwenty,
  moderndrama,
  heritage,
  handwriting,
  cambprose,
  cambpoetry,
  cambdrama,
  handwritingtwo,
  sevenplus,
  sevenplustwo,
  sevenplusthree,
  sevenplusfour,
  sevenplusfive,
  sevenplussix,
  sevenplusseven,
  sevenpluseight,
  elevenplus,
  elevendulwich,
  elevenallrounder,
  elevenpaulsgirls,
  elevencollegiate,
  elevenwinchester,
  thirteencheltenham,
  elevenmerchant,
  trilogy,
  mcs,
  lease,
  promptbuilderextract,
  promptbuilder3extract,
  rentreview,
];

export default TOOLS;
