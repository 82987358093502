import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // Import withRouter
import { CheckIcon } from "@heroicons/react/outline";
import { observer, inject } from "mobx-react";

@inject("store")
@observer
class EntryPromptBase extends Component {
  suggestTopic = () => {
    const topics = [
      {
        topic: "A storm",
        opening: "Thunder roared \n as lightning flashed across the dark sky.",
      },
      {
        topic: "The hurricane",
        opening: "The wind screamed against\n\n the window panes.",
      },
      // Additional topics can be added here
    ];

    const randomIndex = Math.floor(Math.random() * topics.length);
    const randomTopic = topics[randomIndex];

    this.props.prompt.prompts[0].value = randomTopic.topic;
    this.props.prompt.prompts[1].value = randomTopic.opening;
  };

  generateStoryPrompt = () => {
    const storyPrompts = [
      {
        topic: "Lost in the woods",
        opening: "The forest seemed to close in around me as I walked.",
      },
      {
        topic: "A mysterious castle",
        opening: "The ancient gates of the castle groaned as they opened.",
      },
      // Additional story prompts can be added here
    ];

    const randomIndex = Math.floor(Math.random() * storyPrompts.length);
    const randomPrompt = storyPrompts[randomIndex];

    this.props.prompt.prompts[0].value = randomPrompt.topic;
    this.props.prompt.prompts[1].value = randomPrompt.opening;
  };

  render() {
    const { currentPrompt, disabled, index, location } = this.props; // Destructure location from props
    let hidden = currentPrompt !== index;

    return (
      <div
        className={`align-bottom bg-white md:rounded-md text-left overflow-hidden transform transition-all sm:align-middle transition hover:shadow-md shadow-2xl focus:shadow-2xl md:mb-8 ${
          hidden ? "hidden" : ""
        }`}
      >
        <div className="px-6 py-6">
          <div className="flex items-center">
            <div
              className={`flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-${
                !disabled ? "green" : "gray"
              }-300 sm:mx-0 sm:h-10 sm:w-10 ${
                !disabled ? "bg-green-300" : "bg-gray-300"
              } `}
            >
              <CheckIcon
                className={`h-3 w-3 md:h-6 md:w-6 ${
                  !disabled ? "text-green-700" : "text-gray-500"
                } text-${!disabled ? "green-700" : "gray-500"}`}
                aria-hidden="true"
              />
            </div>

            <div className="mt-0 ml-4 text-left">
              <div
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                {this.props.prompt.title || "Entry Text"}
              </div>

              <p className="text-sm text-gray-500">
                {this.props.prompt.desc || "Write a small bit of text"}
              </p>
            </div>
          </div>
          {/* Conditionally render buttons based on the current pathname */}
          <div className="flex justify-center mt-4">
            {location.pathname.includes("/ai/writing/trilogy") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.suggestTopic}
              >
                Suggest a Topic
              </button>
            )}
            {location.pathname.includes("/ai/writing/mcs") && (
              <button
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-green-400 to-green-400 hover:from-green-400 hover:to-green-600 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={this.generateStoryPrompt}
              >
                Generate a Story Prompt
              </button>
            )}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

// Wrap the EntryPromptBase with withRouter to inject router props
const EntryPrompt = withRouter(EntryPromptBase);

export default EntryPrompt;
