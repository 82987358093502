import {
	AcademicCapIcon,
} from '@heroicons/react/solid'

const obj = {
	title: "English Language Paper One, Question Three",
	desc: "Use this tool to mark responses to questions on structure in English Language, Paper One",
	category: "AQA",
	Icon: AcademicCapIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "green-400",
	toColor: "green-600",

	to: "/ai/writing/englishlanguageonethree",
	api: "/ai/writing/englishlanguageonethree",

	output: {
		title: "English Language Paper One, Question Three",
		desc: "Please wait as we query AI models. This can take up to 2 minutes",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"English Language Paper One, Question Three",
		desc: "Enter the student's essay response to Paper One, Question Three",
		// n: 1,
		prompts: [
			{ 
				title: "Essay", 
				attr: "essay",  
				value: "", 
				placeholder: "Enter the student's response here", 
				label: "Student's response to the Question Three",
				type: "textarea",
				maxLength: 3500,
				// max: 100,
				min: 400,
				required: true,
				error: "",
				example: "At the beginning of the source, the Major’s day begins as normal, a fact emphasized by the writer’s repeated use of ‘usual’ to describe his clothing and his walk. However, by the end of the extract, the Major is in a precarious position with a flowerbed threatening his death. This transition across fifty lines keeps the reader interested, for it shows how the Major, in spite of the emphasis on his normal life, somehow gets himself into a rather awkward situation.\n\n The longer paragraphs of the source have an ambling quality: they move through topics easily with events happening as if randomly. The writer opens a paragraph with the observation ‘How it happened no one will ever know’. The first paragraph of the source describes not only the beginnings of his walk but also the man and his wheelbarrow. Throughout the passage, the long sentences together imply how the Major’s attention casually moves from topic to topic. As readers, we are made to focus on these random observations, yet because of the Major’s eccentricity it is curious to understand his worldview, such as his description of the backs of houses as like ‘being behind the scenes of a theatre’.\n\nThe writer also uses sentence length to suggest the Major’s bargaining ability. Across the long sentence, the Major praises the roots and creates a scale for the flowers, implying his knowledge and ability, only for the writer to insert the fact that he ‘bought them all.’ Thus the Major is made to seem both knowledgeable and foolish. The writer therefore uses structure to create a character who is an overall unusual man, his trip quickly turning into a farcical situation that can only end badly for him. Nonetheless, he is an engaging character."
			},
		],
		example: {
    output: "Percentage likelihood the student's response has been AI generated: 0%\nAO2 Score: 8\nTotal Score: 8.0 / 8\nFeedback: \n\n> The student's response demonstrates a perceptive and detailed understanding of structural features in the text. They show an excellent analysis of the effects of the writer's choices of structural features, giving a range of judicious examples and making sophisticated and accurate use of subject terminology.\n\n> The student is correct in recognizing that the writer uses the Major's routine and normality at the beginning of the extract to create an unexpected and intriguing transition later in the text, as the Major finds himself in a precarious position with a flowerbed threatening his death. They also note how the longer paragraphs of the source have an ambling quality that moves through topics seemingly randomly, which adds to the overall sense of eccentricity surrounding the character of the Major. The use of sentence length to suggest the Major's knowledge and ability, only to reveal that he bought all the flowers, highlights the character's overall unusualness and farcical nature. \n\n> The student's response is well-written and demonstrates a deep understanding of the text and the structural features used in it. They have effectively analysed and explained how the writer's use of structure has created an engaging and eccentric character that keeps the reader interested throughout the passage.",			// outputs: [],
		// Icon: RefreshIcon,
		// color: "",
	}
}]
	
}

export default obj
