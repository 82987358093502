import { AcademicCapIcon } from "@heroicons/react/solid";

const obj = {
  title: "Lease analyser",
  desc: "Use this tool to analyse leases",
  category: "trilogy",
  Icon: AcademicCapIcon,
  // tags: [],
  permissions: ["user"],

  fromColor: "purple-400",
  toColor: "purple-600",

  to: "/ai/writing/lease",
  api: "/ai/writing/lease",

  output: {
    title: "Prompt Builder",
    desc: "Please wait as we query AI models. This can take up to 2 minutes.",
    // Icon: RefreshIcon,
    // color: "",
  },

  prompts: [
    {
      title: "Prompt Builder",
      desc: "Enter your essay response",
      // n: 1,
      prompts: [
        {
          title: "Mark Scheme (Upload .pdf of the mark scheme).",
          name: "scheme", // This should match the multer configuration on the server
          attr: "scheme", // This should match the multer configuration on the server
          value: "",
          label: "Upload your PDF file",
          type: "file",
          required: true,
          error: "",
        },
      ],
      example: {
        output: ".", // outputs: [],
        // Icon: RefreshIcon,
        // color: "",
      },
    },
  ],
};

export default obj;
