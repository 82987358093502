import {
	AcademicCapIcon,
} from '@heroicons/react/solid'

const obj = {
	title: "English Language Paper Two, Question Two",
	desc: "Use this tool to mark responses to questions on language in English Language, Paper Two",
	category: "AQA",
	Icon: AcademicCapIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "green-400",
	toColor: "green-600",

	to: "/ai/writing/englishlanguagetwotwo",
	api: "/ai/writing/englishlanguagetwotwo",

	output: {
		title: "English Language Paper Two, Question Two",
		desc: "Please wait as we query AI models. This can take up to 2 minutes",
		// Icon: RefreshIcon,
		// color: "",
	},

	prompts: [{
		title:"English Language Paper Two, Question Two",
		desc: "Enter your response to the qustion",
		// n: 1,
		prompts: [
			{ 
				title: "Essay", 
				attr: "essay",  
				value: "", 
				placeholder: "Enter your essay response here", 
				label: "Your essay response to Question Two",
				type: "textarea",
				maxLength: 3500,
				// max: 100,
				min: 400,
				required: true,
				error: "",
				example: "Both Dickens and Orwell emphasise how unpleasant the workhouse and coal mine are respectively. In Source A, the machines are noted as ‘roaring’, suggesting how loud they are. It is described how a visitor to the coal mine must ‘crawl’ to get to the coal face: from this it can be inferred that deep underground there were numerous tunnels instead of actual passages to walk around. Even visiting the space, then, means getting dirty and forcing oneself into a claustrophobic situation. Against this might be compared the almost cavernous workhouse, which is ‘inhabited by a population’ that range in age from new-born children to old men dying. The space is created as offering no solace for anyone of any age: to be in the workhouse means to face a life of poverty. There is little hope, then, should you end up in either a coal mine or a workhouse.\n\nThese spaces are also described as having a religious significance in that they are either like hell or some other place where torture and uncomfortable conditions are common. That the men are ‘half-naked’ in Source A suggests the heat of the mine. These workers are also ‘kneeling’ – the conditions in which they work, then, are certainly below any standard that we might expect today. The writer explains how the place is ‘like hell’, which is then supported through the list, which outlines the ‘heat, noise, confusion darkness, foul air’ and the ‘unbearably cramped space’ of the mine. The workhouse environment in Source B, however, is also unforgiving, as evidenced by the story of the young child who dies in the second paragraph. The yard is also described as ‘squalid’, suggesting how dirty it is. Another room of the building is described as ‘a kind of purgatory’ – like the mine, this is a transitional space in which people die. Both texts then use references to hell, life and death to suggest the overall poor conditions."
			},
		],
		example: {
    output: "Percentage likelihood the student's response has been AI generated: 0%\nAO1 Score: 8\nTotal Score: 8.0 / 8\nFeedback: \n> The student provides a perceptive and detailed analysis of both texts, making perceptive inferences and using relevant textual detail to support their response. The student shows a clear understanding of the differences between the workhouse and the coal mine and how each is presented in the respective texts. \n\n> The student highlights the similarities between the two texts, in that they both emphasize the unpleasantness of the workhouse and coal mine respectively. The student uses specific examples, such as the heat of the mine and the squalid yard of the workhouse, to support their argument. \n\n> Additionally, the student explores the religious significance of these spaces, noting how they are likened to hell or purgatory. The use of these religious tropes emphasizes how grim and hopeless the conditions are for those living and working in these environments. \n\n > In conclusion, this is a strong response that shows a perceptive understanding of both texts and their presentation of the workhouse and coal mine. The student provides a detailed analysis, supported by textual evidence, and shows clear links between the representations of both spaces. \n\n > To improve their answer, the student could have explored more the significance of this theme of hell, life, and death being used as metaphors for the conditions of the workhouse and coal mine, and how this affects our understanding of the texts. But overall, this is an excellent response. \n\nEND OF RESPONSE",			// outputs: [],
		// Icon: RefreshIcon,
		// color: "",
	}
}]
	
}

export default obj
